import Q from "q";
import _ from 'underscore';

import validator_check_phone from "nllib/src/verification/element-ui/el-form/validator_check_phone";
import check_phone from "nllib/src/verification/check_phone";
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";

import userBuyer from "@/lib/data-service/default/web_common_userBuyer_new";
import getInlandAreaChildListByParentIdResponse
    from "@/lib/data-service/default/web_common_area_getInlandAreaChildListByParentIdResponse";
import kaptcha from "@/lib/data-service/default/web_common_kaptcha";
import phoneRegister from "@/lib/data-service/default/web_common_register";
import vueCheckUserName from "@/lib/data-service/default/web_common_user_vueCheckUserName";
import web_common_user_checkPhone from "@/lib/data-service/default/web_common_user_checkPhone";
import {STATUS} from "@/lib/deprecated/utils/status";

import liability from "@/page/distributor/other/aboutUs/text/liability";
import knowledge from "@/page/distributor/other/aboutUs/text/knowledge";
import personageComponent from "@/page/supplier/personageRegister";
import userBuyer_findOne from "@/lib/data-service/default/web_common_userBuyer_findOne";
import updateBuyer from "@/lib/data-service/default/web_common_userBuyer_updateBuyer";
import verification from "@/lib/deprecated/utils/verification/1.0.2";

const SUCCESS_CODE = STATUS.success;
export default {
    data() {
        return {
            formData: {
                userName: "",
                airEmail: "",
                airLinkman: "",
                airPhone: "",
                airQq: "",
                areaCity: "",
                areaCityName: "",
                areaProvince: "",
                areaProvinceName: "",
                areaRegion: "",
                areaRegionName: "",
                businessLicenseNum: "",
                businessLicensePic: "",
                buyerLevel: 0,
                buyerType: "1",
                carryRules: 0,
                commonSeal: "",
                companyAddress: "",
                companyId: 0,
                companyName: "",
                creditId: 0,
                financeFax: "",
                idCard: "",
                idCardFront: "",
                idCardReverse: "",
                officeId: "",
                password: "",
                phone: "",
                smsCode: "",
                touristBusinessLicense: "",
                touristBusinessLicenseNumber: "",
                travelEmail: "",
                travelLinkman: "",
                travelPhone: "",
                travelQq: "",
                areaCitys: [],
            },
            selectCityStatu: false,
            ossClient: new OssClient(),
            personShow: false,
            protocolText: {
                show: false,
                show1: false,
                text: liability,
                text1: knowledge,
                title: "网站声明",
                title1: "知识产权声明",
            },
            fileListImg: {
                idCardFront: [],
                idCardReverse: [],
                businessLicensePic: [],
                commonSeal: [],
                touristBusinessLicense: [],
            },
            imageUrl: {
                businessLicensePic: '',
                idCardFront: '',
                idCardReverse: '',
                touristBusinessLicense: '',
                commonSeal: '',
            },
            uploadLoading: {
                businessLicensePic: false,
                idCardFront: false,
                idCardReverse: false,
                touristBusinessLicense: false,
                commonSeal: false,
            },
            authCode: {
                url: "",
                value: "",
                show: false,
            },
            getCodePhone: {
                staut: false,
                show: false,
                num: 60,
                text: "获取短信验证码",
            },
            phoneStatu: false,
            agreeStatu: false,
            registrationList: {},
            userType: "distributor-registration",
            siteList: {}, //地址
            options: [],
            password: "",
            rules: {
                //验证
                userName: [
                    {
                        validator: verification.checkUser,
                        trigger: "blur",
                        required: true,
                    },
                ],
                password: [
                    {
                        validator: verification.checkPassWord,
                        trigger: "blur",
                        required: true,
                    },
                ],
                passwordAgain: [
                    {
                        validator: verification.validatePass,
                        trigger: "blur",
                        required: true,
                    },
                ],
                phone: [
                    {
                        validator: validator_check_phone,
                        trigger: "blur",
                        required: true,
                    },
                ],
                smsCode: [{required: true, message: "请输入验证码", trigger: "blur"}],
                companyName: [
                    {required: true, message: "请输入公司名称", trigger: "blur"},
                ],
                companyAddress: [
                    {required: true, message: "请输入公司地址", trigger: "blur"},
                ],
                businessLicenseNum: [
                    {
                        validator: verification.checkBusinessLicense,
                        trigger: "blur",
                        required: true,
                    },
                ],
                businessLicensePic: [
                    {
                        required: true,
                        message: "请上传统一社会信用代码图片",
                        trigger: "blur",
                    },
                ],
                airLinkman: [
                    {required: true, message: "请输入联系人", trigger: "blur"},
                ],
                airPhone: [
                    {
                        validator: validator_check_phone,
                        trigger: "blur",
                        required: true,
                    },
                ],
                travelLinkman: [
                    {required: true, message: "请输入联系人", trigger: "blur"},
                ],
                travelPhone: [
                    {
                        validator: validator_check_phone,
                        trigger: "blur",
                        required: true,
                    },
                ],
                areaCitys: [{required: true, message: "请选择城市", trigger: "blur"}],
                touristBusinessLicenseNumber: [
                    {
                        validator: verification.checkTouristBusinessLicenseNumber,
                        trigger: "blur",
                        required: true,
                    },
                ],
                touristBusinessLicense: [
                    {
                        validator: verification.checkTouristBusinessLicensePic,
                        required: true,
                    },
                ],
                idCard: [
                    {
                        required: true,
                        validator: verification.checkId,
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    components: {personageComponent},
    created() {
        this._getInlandArea("100000", (res) => {
            this.$set(this.siteList, "area", res);
        });
        verification.self = this;
        if (this.$route.query.type == 3) {
            this.formData.buyerType = "3";
            this.personShow = true;
        } else if (this.$route.query.type) {
            let id = this.$route.query.id;

            this.getInfo(id);
        }
    },
    watch: {
        formData: {
            handler(newVal) {
                verification.formData = newVal;
            },
            deep: true,
        },
    },
    methods: {
        getInfo(buyerId) {
            userBuyer_findOne({buyerId}).then((res) => {
                let data = Object.assign(this.formData, res.result);
                let imgList = [
                    "businessLicensePic",
                    "idCardFront",
                    "idCardReverse",
                    "touristBusinessLicense",
                    "commonSeal",
                ];
                imgList.map((name) => {
                    if (data[name]) {
                        this.fileListImg[name].push({
                            name: name,
                            url: data[name],
                        });
                        document.querySelector(`#${name} .el-upload`).style.display =
                            "none";
                    }
                });
                data.areaRegions = data.areaRegionName;
                data.areaProvinces = data.areaProvinceName;
                data.areaCitys = data.areaCityName;
                this.formData = data;
            });
        },
        getFailReason(val) {
            // this.formData.failReason = val;
            this.$set(this.formData, "failReason", val);
        },
        goRegister(type) {
            type == 3 ? (this.personShow = true) : (this.personShow = false);
            // this.$router.push({ name: path, query: { type: type } });
        },
        //获取产品图片上传的地址
        uploadChange(file, name, from) {
            this.$set(this.uploadLoading, name, true);
            this.ossClient.multipartUpload({
                file: file.file,
            })
                .then((res) => {
                    this.formData[name] = res.url;
                    this.$message({type: "success", message: "上传图片成功"});
                    if (name === "businessLicensePic") this.$refs[from].validateField("businessLicenseNum");
                    if (name === 'touristBusinessLicense') this.$refs[from].validateField("touristBusinessLicenseNumber");
                    this.$set(this.imageUrl, name, res.url)

                    // this.$refs[from].validateField(name);
                    // document.querySelector(`#${name} .el-upload`).style.display = "none";
                })
                .catch(() => {
                    this.$message.error('上传图片失败');
                })
                .finally(() => {
                    this.$set(this.uploadLoading, name, false);
                });
        },
        //检验img格式
        detectionImg(file) {
            var reg2 = /^(\s|\S)+(jpg|png|bmp|jpeg)+$/;
            const isLt2M = file.size / 1024 < 10240;
            if (!reg2.test(file.name)) {
                this.$message({type: "warning", message: "图片格式上传错误！"});
                return false;
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 10M!");
                return false;
            }
        },
        //处理产品图片的删除
        handleRemoveImg(file, name, from) {
            this.formData[name] = "";
            name === "businessLicensePic"
                ? this.$refs[from].validateField("businessLicenseNum")
                : "";
            name === "touristBusinessLicense"
                ? this.$refs[from].validateField("touristBusinessLicenseNumber")
                : "";
            document.querySelector(`#${name} .el-upload`).style.display = "block";
        },
        //检查用户名
        queryUserName() {
            const name = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
            if (!this.formData.userName) {
                return;
            } else {
                if (!name.test(this.formData.userName)) return;
            }
            vueCheckUserName({userName: this.formData.userName}).then((res) => {
                if (res.code === SUCCESS_CODE) {
                    !res.flag
                        ? this.$message({type: "warning", message: "用户已注册"})
                        : "";
                }
            });
        },
        //获取短信
        getNote() {
            if (!this.formData.phone) {
                return this.$message({type: "warning", message: "请输入手机号码"});
            }

            if (!check_phone({value: this.formData.phone})) {
                return this.$message({
                    type: "warning",
                    message: "电话号码格式不正确",
                });
            }
            this.authCode.value = "";
            this.authCode.show = true;
            kaptcha().then((res) => {
                if (res.code === SUCCESS_CODE) {
                    this.authCode.url = res.img;
                    this.authCode.uuid = res.uuid;
                    this.$refs.authCode.focus();
                }
            });
        },
        //手机验证码
        getPhoneAuthCode() {
            let authCode = this.authCode;
            if (authCode.value) {
                authCode.show = false;
                let data = {
                    phoneNumber: this.formData.phone,
                    verificationCode: authCode.value,
                    roleType: 2,
                    uuid: authCode.uuid,
                };
                this.getCodePhone.statu = true;
                phoneRegister(data).then(
                    (res) => {
                        if (res.code === SUCCESS_CODE) {
                            this.getCodePhone.statu = false;
                            this.getCodePhone.show = true;
                            this.getCodePhone.text = "重新发送";
                            let codeNmu = setInterval(() => {
                                this.getCodePhone.num--;
                                if (this.getCodePhone.num < 1) {
                                    clearInterval(codeNmu);
                                    this.getCodePhone.text = "获取短信验证码";
                                    this.getCodePhone.show = false;
                                    this.getCodePhone.num = 60;
                                }
                            }, 1000);
                        }
                    },
                    (error) => {
                        this.getCodePhone.statu = false;
                        this.$forceUpdate();
                    }
                );
            }
        },
        //提交表单
        submitForm() {
            let formRuleName = [];
            let _this = this;
            let data = this.formData;
            this.formData.buyerType == 1
                ? (formRuleName = ["userForm", "companyForm", "airForm"])
                : this.formData.buyerType == 2
                ? (formRuleName = ["userForm", "companyForm", "travelForm"])
                : (formRuleName = ["userForm", "companyForm", "airForm", "travelForm"]);
            let statu = formRuleName.map((item) => {
                let _s = Boolean;
                this.$refs[item].validate((valid) => {
                    if (valid) {
                        _s = true;
                        if (this.$route.query.type) {
                            if (data.areaCitys && this.selectCityStatu) {
                                data.areaRegion = data.areaRegions[0];
                                data.areaProvince = data.areaProvinces[0];
                                data.areaCity = data.areaCitys[0];
                                data.areaRegionName = data.areaRegions[1];
                                data.areaProvinceName = data.areaProvinces[1];
                                data.areaCityName = data.areaCitys[1];
                            }
                        } else {
                            if (data.areaCitys) {
                                data.areaRegion = data.areaRegions[0];
                                data.areaProvince = data.areaProvinces[0];
                                data.areaCity = data.areaCitys[0];
                                data.areaRegionName = data.areaRegions[1];
                                data.areaProvinceName = data.areaProvinces[1];
                                data.areaCityName = data.areaCitys[1];
                            }
                        }
                    } else {
                        _s = false;
                    }
                });
                return _s;
            });
            statu = statu.every((item) => item === true);

            if (statu) {
                // if(!data.businessLicenseNum||!data.businessLicensePic)return
                if (!this.agreeStatu)
                    return this.$message({
                        type: "warning",
                        message: "请勾选服务协议！",
                    });
                data.idCard = data.idCard.replace(/\s+/g, "");
                data.businessLicenseNum = data.businessLicenseNum.replace(/\s+/g, "");
                if (this.$route.query.type) {
                    data.companyId = this.$route.query.id;
                    updateBuyer(data).then((res) => {
                        _this.$router.push({
                            name: "register-success",
                            query: {type: "4"},
                        });
                    });
                } else {
                    userBuyer(data).then((res) => {
                        _this.$router.push({
                            name: "register-success",
                            query: {type: "4"},
                        });
                        // let url = get_url_prefix({environment: global.appConfig.environment, appKey: 'default'})
                        // window.location.href = url
                    });
                }
            } else {
                this.$nextTick(() => {
                    let firstEle = document.getElementsByClassName(
                        "el-form-item__error"
                    )[0];
                    document.documentElement.scrollTop =
                        document.documentElement.scrollTop +
                        firstEle.getBoundingClientRect().top -
                        firstEle.parentElement.offsetHeight -
                        18;
                });
            }
        },
        //获取城市列表
        _getInlandArea(id, callback) {
            getInlandAreaChildListByParentIdResponse({id: id}).then((res) => {
                if (res.code === SUCCESS_CODE) {
                    callback(res.areaTreeResult);
                }
            });
        },
        //选中大区
        _getArea(val) {
            this.selectCityStatu = true;
            this._getInlandArea(val[0], (res) => {
                this.formData.areaProvinces ? (this.formData.areaProvinces = "") : "";
                this.formData.areaCitys ? (this.formData.areaCitys = "") : "";
                this.$set(this.siteList, "province", res);
                this.$set(this.siteList, "city", []);
            });
        },
        //选中省份
        _getProvince(val) {
            this._getInlandArea(val[0], (res) => {
                this.formData.areaCitys ? (this.formData.areaCitys = "") : "";
                this.$nextTick(() => {
                    this.$set(this.siteList, "city", res);
                });
            });
        },
    },
};
